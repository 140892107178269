import { useEffect, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLocale } from "./useLocale"

const query = graphql`
  query useTranslations {
    allFile(filter: {relativeDirectory: {eq: "translations"}}) {
      edges {
        node {
          name
          childrenTranslationsJson {
            title
            whoWeAre
            allEquipment
            cameraEquipment
            gimbals
            grip
            video
            audio
            lighting
            power
            consumables
            misc
            accessories
            timecode
            mixers
            storage
            monitors
            cables
            media
            headphonesPreamp
            timecodeSync
            receivers
            transmitters
            support
            lenses
            fe
            pl
            tripods
            wireless
            microphones
            homeIntroH1
            homeIntroP1
            homeIntroP2
            homeIntroP3
            homeIntroP4
            homeContactH1Part1
            homeContactH1Part2
            homeContactH1Part3
            homeContactH1Part4
            mailUs
            callUs
            visitUs
            titlePaal
            titleReiel
            titleStian
            titleBrent
            homeFooterSupport
            homeFooterTerms
            menuSupport
            menuManuals
            menuReport
            menuRental
            supportIntro
            subrented
            error404
            info404
            link404
            homeWelcomeH1
            homeWelcomeP

            whoWeAreParagtaph
            homeIntroP
            reachOutHeader
            reachOutText

            intercom
            light
            equipment
            sanken
            miscellaneous
            sdi
            dji
            kits
            parts
            goPro

            cookieBanner {
              acceptButton
              description
              title
              removeButton
            }
          }
        }
      }
    }
  }
`




const useTranslation = () => {
  const { locale } = useLocale()
  const { allFile } = useStaticQuery(query)

  const translations = useMemo(() => {
    const currentFile = allFile.edges.find(edge => edge.node.name === locale)
      ?.node.childrenTranslationsJson[0]

    return currentFile || {}
  }, [allFile, locale])

  // Extract all lists from GraphQL query response
  const queryList = allFile.edges.map(item => {
    const currentFileTitle = Object.keys(item.node).filter(
      item => item !== "name",
    )[0]

    return {
      name: item.node.name,
      ...item.node[currentFileTitle][0],
    }
  })

  const websiteText = queryList.find(lang => lang.name === locale) || {};

  // useEffect(() => {
  //   websiteText = queryList.filter(lang => lang.name === locale)[0]
  // }, [locale])

  return {
    translations: {
      "All Equipment": translations.allEquipment,
      "Camera equipment": translations.cameraEquipment,
      Gimbals: translations.gimbals,
      Grip: translations.grip,
      Video: translations.video,
      Audio: translations.audio,
      Lighting: translations.lighting,
      Power: translations.power,
      Consumables: translations.consumables,
      Miscellaneous: translations.misc,
      Accessories: translations.accessories,
      Intercom: translations.intercom,
      Timecode: translations.timecode,
      Mixers: translations.mixers,
      Storage: translations.storage,
      Monitors: translations.monitors,
      Cables: translations.cables,
      Media: translations.media,
      "Headphones | Preamp": translations.headphonesPreamp,
      "Timecode | Sync": translations.timecodeSync,
      Receivers: translations.receivers,
      Transmitters: translations.transmitters,
      FE: translations.fe,
      PL: translations.pl,
      Tripods: translations.tripods,
      Lenses: translations.lenses,
      Support: translations.support,
      Wireless: translations.wireless,
      Microphones: translations.microphones,
      subrented: translations.subrented,
      error404: translations.error404,
      info404: translations.info404,
      link404: translations.link404,
      Light: translations.light,
      Equipment: translations.equipment,
      Sanken: translations.sanken,
      Miscellaneous: translations.miscellaneous,
      Sdi: translations.sdi,
      Dji: translations.dji,
      Kits: translations.kits,
      Parts: translations.parts,
      GoPro: translations.goPro
    },
    cookieBanner : translations.cookieBanner,
    websiteText,
    locale
  }
}

export default useTranslation







